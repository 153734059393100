
.toggle-container{
    position: relative;
    top: 0;
    left: 0;
    height: 2rem;
    cursor: pointer;
}
.drawing-button {
    position: absolute;
    left:0;
    top:0;
}
.photo-button {
    position: absolute;
    left:7rem;
    top:0;
}

.button-style {
    width: 6rem;
    height: 2rem;
    padding: 0.2rem;
    border: 1px solid black;
    border-radius: 2rem;
    text-align: center;
    z-index: 2;
}

#overlay{
    position: absolute;
    left:0;
    top:0;
    width: 6rem;
    height: 2rem;
    transition: margin-left 3s;
    opacity: 0.5;
    background-color: gray;
    border-radius: 2rem;
    z-index: 1;
}

.overlay-left{
    margin-left: 0;
}

.overlay-right{
    margin-left: 7rem;
}