


.instagram {
}

#back-to-top {
    cursor: pointer;
}

#coffee img{
    height: 60px;
    width: 217px;
}