.drawingDetail{
    padding-top: 4rem;
}

.image-container {
    width: 100%;
    margin: auto;
    position: relative;
    top:0;
    left:0;
    cursor: pointer;
}

.image-container img {
    transition: opacity 3s;
}

@media screen and (min-width: 600px) {
    .image-container {max-width: 600px;}
    .drawing-image {max-width: 600px;}
    .photo-image {max-width: 600px;}
}

.drawing-image {
    max-width: 100%;
    position: relative;
    top: 0;
    left: 0;
}

.photo-image {
    max-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.transparant{
    opacity: 0;
}

@keyframes course {
    from {opacity: 0;}
    to {opacity: 1;}
}

/* The element to apply the animation to */
#course {
    opacity: 1;
    animation-name: course;
    animation-duration: 5s;
}

.pointer{
    cursor: pointer;
}