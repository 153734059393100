header {
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 1500px;
    background-color: white;
    z-index: 5;
}

.header-left{
    width: 25%;
    float: left;
}

.header-center{
    width: 50%;
    float: left;
}

.header-right{
    width: 25%;
    float: left;
}