
h1 {
  color: black;
  text-align: center;
}

.no-underline {
  text-decoration: none;
}
.drawing {
  margin: 2rem;
}

.detailImage{
  max-width: 800px;
}

nav {
  background-color: light-blue;
}

